.resolver-container {

  // make siblings a higher z-index so they appear above the wallpaper banner
  >.row {
    z-index: 1;
    position: relative
  }
}

.row {
  max-width: 100%;

  &--filters-bar {
    top: auto;
    z-index: 999 !important;
    padding: 1px 0 20px 0 !important;

    @include mq($from: tablet) {
      background-color: $white;
      margin-top: 30px;
      position: sticky !important;
      top: 75px;
      padding: 30px 0 30px 0 !important;
    }

    @include mq($from: desktop) {
      margin-top: 30px;
      position: sticky !important;
      top: 156px;
      padding: 30px 0 30px 0 !important;
    }
  }

  &--sort {
    text-align: right;
    margin-top: 0 !important;
    border-bottom: 1px solid $gray;
  }

  &--top-centre-feature {
    position: relative;
    margin-top: 200px !important;
    margin-bottom: 2.5rem;

    @include mq($from: tablet) {
      margin-top: 162px !important;
    }

    @include mq($from: desktop) {
      margin-top: 233px !important;
    }

    .card--portrait {

      .card__image--portrait {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        bottom: 30px;

        img {
          margin-bottom: 0;
          margin-top: -150px;

          @include mq($from: tablet) {
            margin-top: -180px;
          }

          @include mq($from: desktop) {
            margin-top: -251px;
          }
        }
      }

      .card__content-wrapper {
        min-height: max-content;
        padding-bottom: 0px;

        .badge {
          margin: 10px auto;
        }

        .card__title {
          font-size: 1.9rem !important;
          text-align: center;
          letter-spacing: -1px;
          margin-bottom: 1rem;

          @include mq($from: desktop) {
            font-size: 2.05rem !important;
            letter-spacing: -1px;
          }
        }

        .card__details {
          text-align: center;
        }

        span {
          font-size: 1.125rem;
          text-align: center;
          color: $nearly-black;

          &:first-child {
            font-size: 1.125rem;
            color: $link-color;
            margin: 0 auto;
          }
        }
      }
    }

    .card--pattern-bg.card--portrait {
      margin-bottom: 30px;

      @include mq($from: desktop) {
        margin-bottom: 0;
      }
    }

    .card__content--portrait {
      align-items: center !important;
      margin-top: 0 !important;
    }

  }

  &--top-section {
    margin-top: 40px !important;
    position: relative;

    .column--half {

      @include mq($from: tablet) {
        width: 38% !important;
      }

      @include mq($from: desktop) {
        width: 48.75%;
      }
    }

    .column-2 {

      @include mq($from: tablet) {
        width: 20% !important;
        margin-left: 10px;
      }

      @include mq($from: desktop) {
        width: 23%;
      }
    }

    .column--one-quarter {
      max-width: 100%;
      margin-left: 10px;

      @include mq($from: tablet) {
        max-width: 20%;
      }

      @include mq($from: desktop) {
        max-width: 23%;
      }

      &:last-of-type {
        @include mq($from: tablet) {
          max-width: 38.2%;
        }

        @include mq($from: desktop) {
          max-width: 23%;
        }
      }
    }
  }

  &--top-section2 {
    margin-top: 2rem !important;

    .row--banner {
      margin-top: 30px !important;
    }
  }

  &--add-bottom-space {
    margin-bottom: 170px;
  }

  &--banner {
    margin-top: 0 !important;
    background-color: $light-gray;
    text-align: center;

    padding: 10px 0 !important;

    .banner {
      margin: 20px auto;
    }
  }

  &--heading-section {
    margin-top: 0 !important;

    @include mq($from: desktop) {
      margin-top: 0;
    }

    .heading {
      margin-top: 20px;
      &:first-child {
        margin-top: 0 !important;
      }
    }

    h2 {
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
    }
  }

  &--main-image {
    margin-top: 0 !important;
    text-align: center;
    background-color: $gray;

    & img {
      margin: 0 auto;
      width: auto;
      max-width: 100%;
      height: auto;
      width: 100%;
      border: 1px solid #c8c0c5;
    }
  }

  &--page-intro {
    .column--one-quarter {
      border-top: 1px solid $dark-gray;

      @include mq($from: desktop) {
        margin-top: 6.5rem;
        padding-top: .1rem;
      }
    }
  }


  &--profiles {
    background-image: linear-gradient(180deg, $white 40px, #eee0d1 40px) !important;

    .heading--section {
      display: inline-block;
      padding: 0;
    }

    .card {
      &:hover .badge {
        background: #006d77;
      }

      &__content--portrait {
        align-items: flex-start !important;
      }

      &__title {
        font-weight: bold;
      }

      &__image {
        img {
          @include mq($from: desktop) {
            max-height: 270px;
          }
        }
      }
    }

    .button--link {
      color: #006d77;
      background: transparent !important;
    }

  }


  &+.row {
    margin-top: 10px;
    padding: 0;
    margin-top: 0;

    @include mq($from: tablet) {
      margin-top: 32px;
    }

    &--boxed,
    &--wide {
      padding: 0;
    }
  }

  & .row {
    margin-top: 0;
    padding: 0;
  }

  &--area {
    padding: 15px !important;
    background-image: linear-gradient(180deg, $white 40px, transparent 40px) !important;
  }

  &--narrow {
    max-width: 92vw;
    padding: 0 10px;
    margin: 0 auto !important;
    @include mq($from: desktop) {
    max-width: 885px;}
  }

  &--wide {
    max-width: 92vw;
    padding: 0 10px;
    margin: 0 auto !important;

    @include mq($from: tablet) {
      max-width: 96vw;
      margin: 0 auto !important;
    }

    @include mq($from: desktop) {
      max-width: 1310px;
    }
  }

  &--boxed {
    max-width: 92vw;
    padding: 0 10px;
    margin: 0 auto !important;

    @include mq($from: tablet) {
      max-width: 96vw;
      margin: 0 auto !important;
    }

    @include mq($from: desktop) {
    max-width: 1180px;}
    padding: 0 10px;
  }

  &--bg-image {
    background-repeat: no-repeat;
    background-position: center;
  }

  &--page,
  .page {
    max-width: 1024px;
  }

  &--black {
    max-width: 100vw;
    background-color: $nearly-black;
    color: $white;
    //width: 100vw;
    margin-top: 2rem !important;

    &:first-child {
      padding: 20px 0 !important;
    }

    .row--wide {
      max-width: 1310px;
    }

    ul {
      font-size: 1rem;
      margin-left: 0;
    }

    @include mq($from: mobile) {
      left: 0;
      right: 0;
    }

    @include mq($from: desktop) {
      margin: 0 auto;
      width: 100%;
      position: relative;
    }

    .menu--wrapper {
      background: none;
      height: unset;
      display: flex;
      margin: 0 !important;
    }

    .menu--desktop-wrapper {
      background-color: $nearly-black !important;
      padding: 0px;
      margin: 0 !important;

      .menu--item--link {
        margin-bottom: 3px;
      }

      .menu--item--link a {
        color: $gray;
      }

      .menu--item--link a:hover {
        color: $red;
      }

      li {
        margin-top: 0;
        font-size: 1rem;
      }

    }

    .footer-address {
      padding-left: 1.5rem;

      p {
        font-size: 1rem;
        margin-top: 0;
      }

      .menu--desktop-wrapper ul {
        font-size: 1rem;
      }
    }

    p {
      margin-left: 20px;
      line-height: 1.8;
      @include mq($from: tablet) {
        margin-left: 0px;
        margin-top: 0;
      }
    }
  }

  &--blue {
    padding: 50px 20px 10px;
    background-color: $blue;

    @include mq($from: phablet) {
      padding: 50px 20px;
    }

    & .card {
      margin-bottom: 20px;

      @include mq($from: phablet) {
        margin-bottom: 0;
      }
    }
  }

  &.content-details {
    margin: 10px 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & .stats__heading {
      font-size: 0.875rem;
      color: $grey-medium;
      margin: 0;
    }

    & .image.avatar {
      flex: 0 0 36px;
    }
  }

  &.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;

    @include mq($from: tablet) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &+.row-with-padding {
    padding: 50px 20px 10px;

    @include mq($from: phablet) {
      padding: 50px 20px;
    }

    & .card {
      margin-bottom: 20px;

      @include mq($from: phablet) {
        margin-bottom: 0;
      }
    }
  }

  .button--link:not(.menu--desktop-wrapper button) {
    position: relative;
    display: block;
    margin: 20px auto 3px auto;
    color: $red !important;
    padding-left: 25px;
    background: url("/static/img/arrow-right.svg") no-repeat center left;

    &:hover {
      background: url("/static/img/arrow-right.svg") no-repeat center left;
    }
  }

  &--featured-profiles {
    padding-bottom: 1.5rem !important;

    .card {
      width: 100%;
      margin-right: 0;

      @include mq($from: tablet) {
        width: 31%;
        margin-right: 10px;
        margin-left: 10px;
      }

      @include mq($from: desktop) {
        width: 22.3%;
        margin-right: 40px;
        margin-left: 0;
      }
    }
  }

  &--image-blocks {
    display: flex;
    margin-bottom: 10px;

    @include mq($from: desktop) {
      margin-bottom: 20px;
    }

    .image {
      border: 2px solid $grey-light;
      padding: 10px;
      margin-right: 10px;
      display: flex;
      align-items: center;

      @include mq($from: desktop) {
        margin-right: 20px;
      }
    }
  }

  &--page-intro {
    flex-direction: column !important;

    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &--gating-cta {
    padding: 15px 25px 35px 25px !important;
    margin-top: 1px !important;

    &:first-child {
      margin-top: 35px !important;
      ;
    }

    &__subscribe {
      margin-top: -20px !important;
      margin-bottom: 18px;
      padding-top: 30px !important;
      background: no-repeat center top url(/static/img/arrow-down.svg);

      @include mq($from: desktop) {
        padding-top: 20px !important;
      }
    }
  }

  &--already-registered {
    display: block;
    margin: 25px 0 35px 0 !important;

    p {
      text-align: center;
      margin: 0;
      width: auto;
    }

    .button--link {
      margin-top: 5px !important;
      margin-bottom: 45px;
    }
  }

  &--has-banner {
    flex-direction: column !important;
    margin-bottom: 30px !important;

    @include mq($from: desktop) {
      flex-direction: row !important;
      margin-bottom: 0;
    }

    .column--three-quarters {
      max-width: 100%;

      @include mq($from: desktop) {
        max-width: 74.5%;
      }
    }

    .column--one-quarter {
      max-width: 100%;

      @include mq($from: desktop) {
        max-width: 23%;
      }
    }

    .column--sponsor {
      max-width: 100%;

      @include mq($from: tablet) {
        max-width: 23%;
        margin-right: 20px;
      }
    }

    .column--two-thirds {
      max-width: 100%;

      @include mq($from: desktop) {
        max-width: 66%;
      }
    }

    .column--one-third {
      max-width: 100%;

      @include mq($from: desktop) {
        max-width: 31.5%;
      }
    }

    .column--half {
      max-width: 100%;

      @include mq($from: desktop) {
        max-width: 48.75%;
      }
    }

    .grid--four {
      @include mq($from: tablet) {
        // grid-template-columns: repeat(2, 1fr) !important;
      }

      @include mq($from: desktop) {
        grid-gap: 30px;
        // grid-template-columns: repeat(4, 1fr) !important;
      }
    }
  }

  &--more-story {
    .summary {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .card__image--landscape {
      min-width: 194px;
      @include mq($from: desktop) {
        min-width: auto;
      }
    }
  }

  &--more-features {
    margin-bottom: 30px !important;
    margin-top: -6px !important;

    .card__title--landscape {
      font-size: 1rem !important;
      font-weight: $font-regular !important;
    }

    .card__image--landscape {
      max-width: 120px;

      img {
        min-width: 120px;
      }
    }
  }

  &--copyright {
    border-top: 1px solid $grey-medium;
    p {
      font-size: 0.812rem;

      a {
        color: white;
        text-decoration: underline;

        &:hover {
          color: darken($color: #ffffff, $amount: 35);
        }
      }
    }
  }
}

.bg-pink {
  background-color: $pink-dark;
  color: $white;
}

.bg-blue {
  background-color: $blue;
  color: $white;

  & .card {
    color: $white;

    & .badge {
      color: $white;
    }

    &__title a {
      border-color: $white;
    }
  }

  & .heading {
    color: $white;
  }

  & a {
    color: $white;
    border-color: $white;

    &:hover {
      border-color: transparent;
    }
  }

}

.members-pictures {
  flex-direction: row !important;

  .column--one-quarter {
    flex: 0 0 23%;
    @include mq($from: tablet) {
      flex: 0 0 100%;
    }
  }

  img {
    width: 100%;
    @include mq($from: tablet) {
      width: auto;
    }
  }
}

.bg-grey {
  background-color: $grey-light;
}

.row.newsletter-cta,
.row.newsletter-cta-wide {
  padding: 30px;
}

.row.newsletter-cta-wide {
  @include mq($from: desktop) {
    padding-left: 230px;
  }
}

.row-related {
  width: 100%;

  @include mq($from: desktop) {
    width: 65%;
    margin-left: 0;
  }

  .card--pattern-bg.card--landscape {
    max-width: 90%;

    @include mq($from: tablet) {
      max-width: 95%;
    }

    @include mq($from: desktop) {
      max-width: 100%;
    }
  }

  .card__image {
    max-width: 100px;

    @include mq($from: tablet) {
      max-width: 150px;
    }

  }
}

.gated-overlay {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
  margin-top: -100px !important;
  padding-top:90px !important;
}

.row:has(+ .gated-overlay) {
  max-height: 120px !important;
  overflow: hidden;
}

.rankings {
  .row {
    .list {
      margin: 0;

      &--full {
        .row {
          margin-top: -8px !important;
        }
      }

      &__item {
        border: none;
        padding-bottom: 0;
      }
    }
  }

  .spacer {
    height: 0;
    @include mq($from: tablet) {
    height: 35px;}
    @include mq($from: desktop) {
      height: 38px;}
    }
  .column {
    &:first-child {
      margin-top: 30px;
      @include mq($from: tablet) {
        margin-top: 0;
        max-width: 31.5%;
      }
    }

    &:last-child {
      margin-top: 30px;
      @include mq($from: tablet) {
        margin-top: 0;
        max-width: 66%;
      }

      h3 {
        margin-top: 30px;
        margin-bottom: 20px;
        @include mq($from: tablet) {
          margin-top: 0;
          margin-bottom: 10px;
        }
        @include mq($from: desktop) {
          margin-top: 1.3rem;
          margin-bottom: 1rem;
        }
      }

      h2 {
       display: none;

        @include mq($from: tablet) {
          display: block;
        }
      }

    }
  }
  h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $grey-medium;
    padding-bottom: 15px;
  }
  p {
    line-height: 2.3;
    margin: 0;
  }

  .grid {
    grid-gap: 20px !important;
  }

  .card {
    &__content {
      padding-left: 0;
      @include mq($from: tablet) {
        flex-direction: row;
        align-items: center;
      }
      .card{
        &__title {
          width: fit-content;
          font-size: 1.125rem !important;
          line-height: 1;
        }
        &__details {
          width: fit-content;
          line-height: 1;
          @include mq($from: tablet) {
          margin-left: 10px;
          padding-left: 10px;
          border-left: 1px solid #ccc;}

          span {
            padding: 0;
            margin: 0 !important;
            @include mq($from: tablet) {
            line-height: 1;
            }
          }
        }
      }
    }
  }

  .list--compact~.list--full {
   .list__item .row {
      margin-top: 15px !important;
    }
  }
}

@media only screen and (max-width: 1320px) and (min-width: 1024px) {
  .row--wide, .row--boxed, .row--narrow{
    max-width: 96vw;
  }

  .row--has-banner {
    flex-direction: column !important;
    margin-bottom: 30px !important;

    .column--one-quarter {
      max-width: 100%;
    }

    .column--two-thirds {
      max-width: 100%;
    }

    .column--one-third {
      max-width: 100%;
    }

    .column--half {
      max-width: 100%;
    }

    .column--sponsor {
      max-width: 23%;
    }

    .grid {
      //grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  .row--black .menu--desktop-wrapper {
    margin-left: 2rem;
    padding: 0;
  }

  .filter .search input {
    height: 48px;
    border-radius: 25px !important;
    min-width: 12.5rem;
  }

  .right-sidebar {
    margin-top: 30px !important;
  }

  .menu--desktop-logo {
    max-height: 65px !important;
    margin-top: -100px !important;
  }
  .menu--desktop {
    margin-top: 0 !important;
  }
  .header-right {
    top: 0;
  }
}


@media only screen and (max-width: 1024px) and (min-width: 960px) {
  .header-right {
    .hide-tablet {
      display: none;
    }
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .grid--four {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .menu--desktop-wrapper li,  header .column .button--link {
    margin-right: 3%;
    font-size: $menu-font-size;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1400px) {
  .menu--desktop-wrapper li:not(.header-right li) {
    margin-right: 3% !important;
 }
}
