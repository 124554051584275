.menu {
  &--desktop-wrapper {
    margin-top: 90px;
  }
  &--desktop-logo {
    width: 270px;
    padding-top: 0;
    margin-top: -126px;
    svg {
      shape-rendering: geometricPrecision;
    }
  }

  &--mobile-logo {
    height: auto;
    padding-top: 10px;

    @include mq($from: mobile) {
      width: 40%;
      padding-top: 10px;
      left: 30%;
    }

    @include mq($from: tablet) {
      width: 20%;
      padding-top: 8px;
      left: 38%;
    }
  }
}

.header-right {
  .menu--desktop-wrapper {
    margin-top: 0px !important;
  }
  .display-row {
    margin-top: 20px !important;
    @include mq($from: desktop) {
      margin-top: 30px !important;
    }

  }
}

.sticky--header {
  height: 75px;
  @include mq($from: desktop) {
  height: 180px;
  }
}


@media only screen and (max-width: 768px) and (min-width: 520px) {
  .menu--mobile-logo {
    padding-top: 10px !important;
    width: 20% !important;
    left: 35% !important;
  }
}

@media only screen and (max-width: 335px) and (min-width: 0px) {
  .menu--mobile-logo {
    padding-top: 15px;
  }

}

.row--banner {
  .column--three-quarters {
    padding-left: 20px;
    justify-content: center;
    max-width: 76%;
  }
  .column--one-quarter {
    padding-right: 10px;
    justify-content: center;
  }
}

h1, h2, h3 {
  font-family: $primary-font;
  font-weight: $font-semi-bold;
}

.heading--large {
  font-family: $primary-font !important;
}

.card__title {
  font-family: $primary-font !important;
}

.row .button--link {
  svg {
    stroke: #ab0000 !important;
  }
}


.details {

  span{
    color: $link-color;
    text-transform: capitalize;
    border-right: 1px solid $link-color;
    line-height: .6;
    display: inline-block;
    font-size: 1rem;
    padding-right: 10px;
    margin-right: 10px;

    + span:last-of-type {
      margin-left: 10px;
    }

    &:last-of-type {
      padding: 0;
      border: none;
      margin: 0;
    }
  }
}

.stats {
  &__value {
    font-size: 1.125rem;
    line-height: 1.8rem;
  }

  &__heading{
    margin-bottom: 0px;
    line-height: 1 !important;

    .name {
      letter-spacing: 0;
    }
    .title a, .company a {
      letter-spacing: 0;
      font-size: 1.3rem;
    }

    span{
      color: $nearly-black;
    }
  }

  &__text {
    margin: 20px 0;
    @include mq($from: desktop) {
    margin: 0 0 10px 0;
    }
  }

}

@media only screen and (max-width: 1320px) and (min-width: 1024px) {
  .sticky--header {
    height: 115px;
  }

  .menu--desktop-wrapper {
    margin-top: 90px !important;
  }
  .header-right .display-row {
    margin-top: 10px !important;
  }

  .menu--desktop-wrapper li {
    margin-right: 20px;
    font-size: 1.05rem;
  }
}
