.menu {
  display: none;

  @include mq($from: desktop) {
    display: flex;
    flex: 1 1 auto;

    & ul {
      display: flex;
    }
  }

  &--item {
    &--link {
      margin: 6px 0;
      &--icon {
        display: flex;
        width: 10px;
        padding-top: 3px;
        margin-left: 8px;
        align-items: center;
      }
    }

  }

  &--wrapper {
    @include mq($from: mobile) {
      background: #F7F7F7;
      height: 70px;
      margin: 0 -10px !important;
    }

    @include mq($from: tablet) {
      background: white;
      height: unset;
      display: flex;
    }

    @include mq($from: desktop) {
      margin: 0 !important;
    }

    &--mobile-open {
      display: block;
      width: 100vw;
    }
  }

  &--strapline {
    position: absolute;
    top: 0px;
    right: 10px;
    width: fit-content;
    z-index: 100;
  }

  &--search-input {
    @include mq($from: desktop) {}

    &__desktop {
      overflow: clip;
    }

    &__mobile {
      overflow: clip;
      position: absolute;
      top: 1rem;
      /* 16px */
      background: none;
      right: 3.5rem;
      /* 56px */
      z-index: 50;

      & input {
        border-radius: 25px;
      }
    }
  }

  &--mobile {
    width: 100%;
    height: 100%;
    background: $white;
    display: flex;
    flex-direction: column;

    @include mq($from: desktop) {
      display: none;
    }
  }

  &--mobile-wrapper {
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    min-height: 80px;
    z-index: 50;
    display: flex;

    @include mq($from: desktop) {
      display: none;
    }
  }

  &--desktop {
    margin-right: 0;
    position: relative;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    font-size: large;
    margin-top: 26px;
    padding-bottom: 0;

    li.mobile-only,
    .button.mobile-only {
      display: block;

      @include mq($from: desktop) {
        display: none;
      }
    }

    /* temp logout link fix */
    & button {
      color: $dark-gray;
      font-size: 1rem;
      margin-bottom: 16px !important;
      margin-top: 0px !important;
      background-color: white !important;
    }

    & li,
    button,
    .button {
      margin-right: 1.5rem;
      margin-left: 0;
      list-style: none;
      font-family: $primary-font;
      font-weight: 400;
      line-height: 1;

      @include mq($from: desktop) {
        margin-right: 2.5rem;
      }

      & a,
      button {
        font-family: $primary-font;
        font-weight: 400;
        line-height: 1;
        color: $nearly-black;

        &:hover {
          color: $primary-color;
        }
      }
    }

    & ul {
      background-color: white;
      list-style: none !important;
    }

    & li>div:not(.menu--item--link),
    & li>div.menu--item-children {
      margin-left: 0;
    }

    & li>div>ul {
      padding: 10px;
      filter: box-shadow(0 2px 2px rgb(0 0 0 / 0.1));
      border-radius: 5px;
      margin-left: -10px;
      box-shadow: 0 0 3px 0 rgb(0 0 0 / 30%);
      margin-top: 20px;
      min-width: 250px;
    }

    & li>div>ul>li {
      padding: 10px !important;
      margin: 0;
      a {
        padding: 0 !important;
      }
    }

    & li>div>ul>li>a {
      font-family: brandon-grotesque, sans-serif;
      font-weight: 500;
      font-size: 18px;
    }

    &__logo-wrapper {
      flex-shrink: 0;
    }
  }

  &--desktop-wrapper {
    margin-left: 0;
    margin-right: auto;
    background-color: white !important;
    flex-shrink: 0 !important;
    margin-top: 0;

    .button.mobile-only {
      display: block;
      color: $nearly-black;
      font-size: $menu-font-size !important;
      margin: 15px auto 10px 0 !important;
      background-color: transparent !important;
      margin-top: 10px;
      border-bottom: none;

      :hover {
        color: $primary-color;
      }

      @include mq($from: desktop) {
        display: none;
      }
    }

    @include mq($from: desktop) {
      margin-top: 90px;
    }

    & ul {
      list-style: none;
    }

    & li {
      margin-top: 0;
      list-style: none;
      font-weight: 400;
      margin-bottom: 0;

      @include mq($from: desktop) {
        padding-bottom: 20px;
      }

      a {
        color: $nearly-black;
      }
    }

    li>div>a {
      padding: 0 !important;
    }

  }

  &--mobile-header {
    display: flex;
    justify-items: center;
    background: #FFF;
  }

  &--mobile-items {
    background-color: #F7F7F7;
    line-height: 3.2rem;
    /* 32px */
    width: 100vw;
    height: 92.5vh;
    padding: 1rem 1rem 2rem 1rem;
    /* 16px */
    cursor: pointer;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    position: absolute;
    margin-left: -6px;
    overflow-y: auto;
    top: 80px;

    & div ul a {
      font-size: 1rem;
      text-decoration: underline;
    }

    :hover {
      color: #e64415;
    }

    li {
      margin-top: 0;
      padding-bottom: 0;
    }
  }

  &--mobile-hamburger-icon {
    width: 1.5rem;
    /* 24px */
    height: 1.5rem;
    /* 24px */
    color: rgb(234 179 8);
  }

  &--mobile-logo {
    background: #f7f7f7;
    width: auto;

    @include mq($from: mobile) {
      visibility: visible !important;
      left: 14%;
    }

    @include mq($from: tablet) {
      visibility: visible !important;
      left: 40%;
    }

    @include mq($from: desktop) {
      visibility: hidden !important;
    }

    z-index: 50;
    position: absolute;
    top: 0.3rem;

    width: 7rem;
    /* 56px */
    background: none;
  }

  &--desktop-logo {
    display: block;
    position: absolute;
    margin-top: -123px;

    &>a {
      flex-shrink: 0 !important;
    }

    @include mq($from: tablet) {
      visibility: visible !important;
      width: 14rem;
      height: 8rem;
      flex-shrink: 0;
      margin-right: 2rem;
    }

    @include mq($from: mobile) {
      visibility: hidden;
      width: 14rem;
      height: 8rem;
    }
  }

  &--mobile-close-icon {
    width: 2rem;
    /* 32px */
    height: 2rem;
    /* 32px */
    color: black;
  }

  &--mobile-button {
    padding: 1rem;
    /* 16px */

    & i {
      & svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  &--user {
    justify-content: flex-end;
    font-size: 0.875rem;
  }

  &--header {
    width: 100%;
    max-width: 1180px;
    margin: 0 30px;
  }

  &__list {
    @extend %list;
    width: 100%;
    max-width: 960px;
    position: relative;
    display: flex;
    color: $black;

    &--main {
      justify-content: space-between;
    }

    &--mobile {
      flex-direction: column;
      max-width: 400px;
      height: 100%;
      padding: 30px 0;
      background: $grey-medium;
    }

    &--user {
      width: auto;
    }
  }

  &__list-item {
    list-style: none;
    display: inline-block;
    white-space: nowrap;

    &:hover {
      @include mq($from: desktop) {
        background-color: $grey-lightest;
      }
    }

    & a {
      display: inline-block;
      color: $grey-dark;
      padding: 20px;
      letter-spacing: $x-small-space;

      &:hover {
        color: $black;
        background-color: $grey;

        @include mq($from: desktop) {
          background: none;
        }
      }
    }

    &--has-submenu {
      position: relative;

      & a {
        padding-left: 20px;

        @include mq($from: desktop) {
          padding-right: 25px;
        }

        &:after {
          content: "";
          display: inline-block;
          width: 5px;
          height: 5px;
          border-top: 2px solid $black;
          border-right: 2px solid $black;
          transform: rotate(135deg);
          position: absolute;
          left: 15px;
          top: 18px;

          @include mq($from: desktop) {
            border-color: $grey-dark;
            left: auto;
            top: auto;
            right: 10px;
            bottom: 27px;
          }
        }

        &:hover:after {
          border-color: $black;

          @include mq($from: desktop) {
            border-color: $black;
          }
        }

        &.open {
          background-color: $grey;

          &:after {
            transform: rotate(315deg);
            top: 20px;
          }
        }
      }
    }



    &--mobile {
      background: none;
      text-align: left;

      & a {
        display: inline-block;
        width: 100%;
        padding: 15px 15px 15px 40px;

        &:hover {
          opacity: 1;
        }
      }
    }

    &--user {
      background: none;
      margin-left: 10px;

      &:hover {
        background: none;
      }

      &:first-child {
        margin-left: 0;
      }

      & a {
        padding: 0;

        &:hover {
          background: none;
        }
      }
    }
  }

  &__children {
    display: none;

    @include mq($from: desktop) {
      left: 0;
      top: 58px;
      position: absolute;
      transition-duration: 0.9s;
      z-index: 120;
      text-align: left;
      border: 1px solid $white;
      border-top: 0;
    }

    &.open {
      display: block;
      margin-bottom: 20px;

      & a {
        background-color: $grey;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    padding: 30px;
    height: 100%;

    @include mq($from: mobileBig) {
      padding: 50px;
    }

    &--mobile {
      padding: 0;
      background-color: $grey;
    }
  }

}

.side-menu {
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  background-color: $nearly-black;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 50px;
  height: calc(100vh - 50px);
  z-index: 99;
  display: none;

  @include mq($from: tablet) {
    display: flex;
    max-width: 300px;
    position: relative;
    top: auto;
    height: 100%;
    z-index: auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    padding: 20px 0;
  }

  &__block {
    display: inline-block;
    width: 100%;
    margin: 20px 0;
    text-align: left;

    @include mq($from: tablet) {
      display: flex;
      flex-direction: column;
    }
  }

  &__block-heading {
    font-weight: 400;
    text-transform: uppercase;
    color: $grey;
    letter-spacing: $small-space;
    padding: 0 25px 2px;
    margin-bottom: 12px;
    font-size: 0.75rem;
  }

  &__list {
    @extend %list;
    border-top: 1px solid $grey-darkest;
  }

  &__list-item {
    &--has-submenu {
      position: relative;

      & a {
        padding-left: 20px;

        @include mq($from: desktop) {
          padding-right: 25px;
        }

        &:after {
          content: "";
          display: inline-block;
          width: 5px;
          height: 5px;
          border-top: 2px solid $white;
          border-right: 2px solid $white;
          transform: rotate(135deg);
          position: absolute;
          right: 20px;
          top: 15px;
        }

        &:hover:after {
          border-color: $primary-color;
        }

        &.open {
          background-color: $grey-darkest;

          &:after {
            transform: rotate(315deg);
            top: 20px;
          }
        }
      }
    }
  }

  &__list-link {
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    color: $white;
    border-left: 5px solid $nearly-black;
    border-bottom: 1px solid $grey-darkest;

    &:hover {
      color: $primary-color;
      text-decoration: none;
      background-color: $grey-darkest;
      border-color: $grey-darkest;
    }

    &.current {
      color: $primary-color;
      border-left-color: $primary-color;
    }
  }

  &__children {
    height: 0;
    overflow: hidden;

    &.open {
      height: 100%;
      overflow: visible;
    }
  }
}

.submenu {
  @include mq($from: desktop) {
    width: 230px;
  }

  &--mobile {
    padding: 0;
  }

  &--side-menu {
    width: 100%;
    margin-bottom: 20px;
    background-color: lighten($nearly-black, 4%);
  }

  &__wrapper {
    position: relative;
    height: auto;
  }

  &__list {
    @extend %list;
  }

  &__list-item {
    & a {
      display: inline-block;
      width: 100%;
      padding: 15px 20px;
      position: relative;

      @include mq($from: desktop) {
        background-color: $grey;
      }

      &:hover {
        @include mq($from: desktop) {
          background-color: $grey-lightest;
        }
      }

      &:after {
        content: "";
        display: none;
      }
    }

    &--side-menu a {
      background: none;
      color: $grey-light;
      padding: 10px 20px 10px 25px;

      &:hover {
        background-color: $nearly-black;
      }
    }
  }
}

.menu__list-item--has-submenu:hover .menu__children {
  @include mq($from: desktop) {
    display: block;
  }
}

.submenu__list-item--has-submenu:hover .submenu {
  @include mq($from: desktop) {
    display: flex;
  }
}

.responsive-nav {
  height: 100%;
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  display: none;
}

#menu-button {
  /*60px*/
  width: 24px;
  /*45px*/
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  & span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: $black;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    /*-webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;*/
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0;
      /*-webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;*/
      transform-origin: left center;
    }

    &:nth-child(2) {
      /*18px*/
      top: 8px;
      /*-webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;*/
      transform-origin: left center;
    }

    &:nth-child(3) {
      /*36px*/
      top: 16px;
      /*-webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;*/
      transform-origin: left center;
    }
  }

  &.open {
    & span {
      &:nth-child(1) {
        /*-webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);*/
        transform: rotate(45deg);
        top: 0;
        left: 3px;
      }

      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &:nth-child(3) {
        /*-webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);*/
        transform: rotate(-45deg);
        top: 17px;
        left: 3px;
      }
    }
  }
}

.search-content {
  top: 35px;
  &--collapse {
    &__wrapper {
      width: 2.75rem;
    }

    &__label {
      position: relative;
      display: block;
    }

    &__icon_wrapper {
      position: absolute;
      top: 13px;
      bottom: 0px;
      right: 0.75rem;
      display: flex;
      justify-items: center;
      padding-left: 2px;
      cursor: pointer;
    }

    &__icon {
      height: 1.25rem;
      /* 20px */
      width: 1.25rem;
      fill: black;
    }

    &__input {
      margin-left: 0px !important;

      &:disabled {
        background-color: white;
      }

      &:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
      }

      display: block;
      background-color: white;
      width: 100%;
      border-radius: 8px;
      border: 1px solid white;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 5px;
      padding-right: 3px;
    }
  }

  &--expanded {
    &__wrapper {
      border-radius: 0;

      @include mq($from: mobile) {
        margin-left: 10px;
        width: 90%;
        position: absolute;
        top: 90px;
      }

      @include mq($from: tablet) {
        margin-left: 5px;
        margin-left: 10px;
        position: unset;
        width: 10rem;
      }

      @include mq($from: desktop) {
        margin-left: 80px;
        position: unset;
        width: 24rem;
      }
    }

    &__label {
      display: block;
      position: relative;
    }

    &__icon_wrapper {
      position: absolute;
      top: 11px;
      right: 11px;
      bottom: 0px;
      display: flex;
      justify-items: center;
      padding-left: 2px;
    }

    &__icon {
      height: 1.25rem;
      /* 20px */
      width: 1.25rem;
      fill: black;
      top: 5px;
      position: absolute;
      right: 2px;
    }

    &__input {

      @include mq($from: mobile) {
        margin-top: 10px;
      }

      @include mq($from: tablet) {
        margin-top: 0px;
      }

      display: block;
      width: 100%;
      padding: 4px;

      &:disabled {
        background-color: white;
      }

      &::placeholder {
        visibility: hidden;
        font-size: small;
        font-weight: $font-regular;
        font-style: normal;
        color: $grey-medium;
      }

      &:focus {
        &::placeholder {
          visibility: visible;

        }
      }

      display: block;
      background-color: white;
      width: 100%;

      border-radius: 8px;
      /* 24px */
      border: 1px solid rgb(155, 153, 153);
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 5px;
      padding-right: 3px;
    }
  }


  &--results {
    &__wrapper {
      background-color: white;
      margin-left: 1px;
      margin-right: 1px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      flex-grow: 0;
      z-index: 50;
      width: 22.5rem;
      right: 10px;
      top: 60px;
      position: absolute;

      @include mq($from: tablet) {
        right: 130px;
      }

      @include mq($from: desktop) {
        width: 25rem;
        right: 0;
        top: 142px;
      }


      &--message {
        font-size: medium;
      }
    }

    &__wrapper:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }

    &__image {
      max-width: 80px;
      max-height: 80px;
    }

    &__message {
      width: 100%;
      font-size: small;
      padding-top: 2px;
    }

    &__title {
      margin-left: 5px;
      text-align: left;
      width: 20rem;
      text-overflow: ellipsis;
      margin-top: -2px;
      line-height: 120%;

      @include mq($from: mobile) {
        width: 18rem;
      }
    }

    &__content {
      background: white;
      display: flex;
      flex-direction: column;
      border: 1px solid #dddddd;
      overflow-y: auto;
      width: 24rem;
      max-height: 300px;
      border-radius: 5px;
      box-shadow: 0 1px 5px 0 rgb(0 0 0 / 30%);

      @include mq($from: tablet) {
        margin-left: 0;

      }

      @include mq($from: desktop) {
        width: 25rem;
      }
    }
  }
}


.header-right {
  padding-top: 0;
  width: 190px;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 999;

  // todo: remove nm-custom style
  // @include mq($from: tablet) {
  //   top: 50%;
  // }

  @include mq($from: desktop) {
    width: auto;
    position: relative;
    padding-top: 20px;
    right: 0;
  }

  .menu {
    &--desktop-wrapper {
      margin-top: 4px;
      margin-right: 0;
      display: flex;
      justify-content: flex-end;
    }

    &--wrapper {
      display: block;
    }

    &--desktop {
      margin-top: 0;
      z-index: 9;

      :first-child {
        margin-left: 0;
      }

      li>div>ul {
        margin-top: 5px;
        min-width: auto;
        width: 200px;

        li {
          margin-bottom: 5px !important;

          a::before {
            content: "";
            margin-left: 0rem;
          }
        }
      }
    }

    &--item--link--icon {
      padding-top: 3px;
      margin-left: -3px;
      margin-right: 8px;
    }
  }

  .display-row {
    margin-top: 20px !important;
    flex-direction: row !important;

    @include mq($from: desktop) {
      width: auto;
      margin-top: auto !important;
      margin-bottom: 23px !important;
      justify-content: space-between !important;
    }

    .column {
      &:last-child {
        max-width: fit-content;
      }
    }

    .column:first-child {
      display: none;

      @include mq($from: desktop) {
        display: block;
      }
    }

    @include mq($from: desktop) {
      flex-direction: column;
      width: 100%;
    }

    .button--link {
      display: none;

      @include mq($from: desktop) {
        display: block;
      }
    }

    .button--cta {
      padding: 2px 10px;
      right: 65px;
      letter-spacing: $small-space;
      position: absolute;
      margin: 0 !important;

      @include mq($from: desktop) {
        font-size: 1rem;
        padding: 5px 20px;
        position: relative;
        right: 10px;
      }
    }
  }

  .search-content--expanded__wrapper {
    margin-left: 0;
    position: unset;
    width: 38px;
    margin-top: -15px;

    @include mq($from: desktop) {
      width: 42px;
      margin-top: 0;
    }
  }

  .search-content--expanded__input {
    border: none;
    margin-top: 0px;
    width: 42px;
    height: 42px;
    border-radius: 25px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    font-size: 1rem;
    font-style: normal;
    color: $grey-dark;
    padding-left: 15px;

    &::placeholder {
      color: transparent;
    }


    &:focus {
      width: 325px;
      margin-left: -282px;

      &::placeholder {
        color: $grey-dark;
      }

      @include mq($from: tablet) {
        width: 500px;
        margin-left: -458px;
      }

      @include mq($from: desktop) {
        width: 400px;
        margin-left: -358px;
        margin-right: auto;
      }
    }
  }

  .search-content--expanded__icon_wrapper {
    position: absolute;
    top: 6px;
    right: -3px;
    bottom: 0px;
    display: flex;
    justify-items: center;
    padding-left: 2px;
    z-index: 1;
  }

  .column--one-third {
    max-width: fit-content;
    bottom: 0;
  }

  .button--link,
  a.button--link {
    margin-top: 10px;

    &::before {
      content: "|";
      color: $grey-medium;
      margin-right: .6rem;
      background-color: transparent !important;
    }
  }

  .menu--desktop li,
  .menu--desktop button {
    margin-right: 0;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: none;

    &:hover {
      color: $primary-color;
    }

    &:first-child {
      margin-right: 0;

      a {
        margin-right: 0;
      }

      a::before {
        content: "";
        margin-left: 0rem;
      }
    }

    &:last-child {
      margin-right: 0;

      a {
        margin-right: 0;
      }
    }
  }

  .menu--desktop li a {
    font-weight: $font-regular;
    color: $dark-gray;
    font-size: 1rem;
    padding-right: .6rem !important;

    &::before {
      content: "|";
      color: $grey-medium;
      margin-right: .6rem;
    }

    &:hover {
      color: $red;
    }
  }
}

.header-right.column--one-quarter {
  @include mq($from: desktop) {
  max-width: 15%;
  }
}

.search-content--results__content .search-content--results__wrapper {
  width: 23rem;
  display: flex;
  flex-direction: row;
  position: relative;
  right: 0;
  top: 0;
  margin-top: 10px;
}


@media only screen and (max-width: 768px) and (min-width: 520px) {
  .menu--mobile-logo {
    width: 45% !important;
    padding-top: 20px !important;
    left: 26% !important;
  }
}

@media only screen and (max-width: 335px) and (min-width: 0px) {
  #mce-EMAIL {
    min-width: 261px !important;
  }

  .menu--mobile-logo {
    width: 50%;
    padding-top: 28px;
    left: 23%;
  }

}

@include mq($from: tablet, $until: desktop) {
  .menu--mobile-wrapper {
    .w-screen {
      width: auto;
    }
  }
  .header-right .display-row {
    position: absolute;
    top: 25px;

    .search-content {
      top: auto !important;
    }

    .search-content--expanded__wrapper {
      margin-top: 0;
    }
  }

}

@media only screen and (max-width: 1320px) and (min-width: 1024px) {
  .menu--desktop-wrapper li {
    margin-right: 1.5rem;
  }
}
